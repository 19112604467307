import React from "react";
import { Box, NavLink, Container } from "theme-ui";

export default function Header() {
  return (
    <Box variant="header">
      <Container>
        <Box mx={-2}>
          {/* <NavLink>Home</NavLink>
          <NavLink>Campaigns</NavLink>
          <NavLink>Reports</NavLink>
          <NavLink>Get Started</NavLink> */}
        </Box>
      </Container>
    </Box>
  );
}
