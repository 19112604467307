import React from "react";
import { Text, Container } from "theme-ui";
import withPage from "../components/layouts/withPage";

const Home = () => {
  return (
    <section>
      <Container>
        <h3>ICM Web Pay</h3>
        <Text mt={10}>
          Handling payments can be a pain. But we have got you covered.
        </Text>

        <Text mt={10}>Get Started</Text>
      </Container>
    </section>
  );
};

export default withPage(Home);
