import React from "react";
import { Box, Container } from "theme-ui";

export default function Footer() {
  return (
    <Box>
      <Container>Footer goes here</Container>
    </Box>
  );
}
